<template>
  <page-content>

    <common-table
      ref="table"
      path="menu"
      :tree="true"
      :pageable="false"
      :columns="columns">

      <template slot="search">
        <a-button type="primary" ghost @click="addMenu" v-hasPermission="['menu:add']">新增菜单</a-button>
        <a-button type="primary" ghost @click="addButton" v-hasPermission="['menu:add']">新增按钮</a-button>
      </template>

      <template slot="icon" slot-scope="{record}">
        <a-icon :type="record.icon"/>
      </template>

      <template slot="type" slot-scope="{record}">
        <a-tag v-if="record.type==0" color="cyan">菜单</a-tag>
        <a-tag v-else color="pink">按钮</a-tag>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-edit v-hasPermission="['menu:update']" @click="edit(record)"></action-edit>
        <action-delete v-hasPermission="['menu:delete']" @click="remove(record)"></action-delete>
        <a-badge v-hasNoPermission="['menu:update','menu:delete']" status="warning" text="无权限"></a-badge>
      </template>

    </common-table>

    <menu-edit ref="menuEdit" @success="getData" ></menu-edit>

  </page-content>
</template>

<script>

  import MenuEdit from './MenuEdit'

  export default {
    name: 'Menu',
    components: { MenuEdit },
    data() {
      return {

      }
    },
    computed: {
      columns() {

        return [
          {
            title: '名称',
            dataIndex: 'text',
          }, {
            title: '图标',
            dataIndex: 'icon',
            scopedSlots: {customRender: 'icon'}
          }, {
            title: '类型',
            dataIndex: 'type',
            scopedSlots: {customRender: 'type'}

          }, {
            title: '地址',
            dataIndex: 'path'
          }, {
            title: 'Vue组件',
            dataIndex: 'component'
          }, {
            title: '权限',
            dataIndex: 'permission'
          }, {
            title: '排序',
            dataIndex: 'order'
          }, {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
          }]
      }
    },

    methods: {

      addMenu(){
        this.$refs.menuEdit.show(0)
      },
      addButton(){
        this.$refs.menuEdit.show(1)
      },
      edit(record) {
        this.$refs.menuEdit.show(record.type,record.id)
      },
      getData(){
        this.$refs.table.getData()
      },

      remove(record) {
        if(record.children && record.children.length>0){
          this.$message.error('请先删除该菜单的下级菜单或按钮')
          return
        }
        let that = this
        this.$confirm({
          title: '确定删除 ' + record.text,
          content: '该操作无法撤销',
          centered: true,
          onOk() {
            that.$delete('menu/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getData()
            })
          }
        })
      },

    }
  }
</script>
<style lang="less" scoped>

</style>
